<template>

    <div>



        <b-modal
      ref="modalTarjetas"
      centered
      size="sm"
      hide-footer
      @hidden="closeModalRecargasTarjetas"
      :title="title"
    >
      <div class="d-block text-center">


        
          <b-row v-if="showCards">
            

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-for="card in cards" :key="card.id">

             <div class="inputGroup ">


                <input :id="card.id" name="radio" @change="onChange($event)"  :value="card.id" type="radio"/>
                <label :for="card.id"> 

                  <span> <img :alt="card.type" style="margin-right: 10px;" :src="card.logoBrand">  </span> Tarjeta {{ card.type }} <br> **** **** **** {{ card.lastNumber }}</label>
              </div>

            </b-col>

              <b-col sm="12" md="12" lg="12" xl="12" style="text-align:right" >

                 <b-button
                    style="margin-top:10px"
                    variant="primary"
                    :disabled="isDisabledSiguiente"
                    @click="hideCards()">

                                Siguiente  <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/> 
                      

                              </b-button>
              </b-col>

                  


          </b-row>

                 <b-row v-else>

            

             
                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p
              class="textLabel"
              style="text-align: center;  margin-bottom: 5px;"
            >
             Seleccione el monto a recargar (*):

                </p>
                </b-col>

            <b-form
              autocomplete="off"
              @submit.prevent="onSubmit"
            >

            <b-row>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="5" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="5" type="radio"/>
                  <label for="5">$5.00</label>
                </div>

  

            </b-col>

            <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="15" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="15" type="radio"/>
                  <label for="15">$15.00</label>
                </div>

  

            </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="25" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="25" type="radio"/>
                  <label for="25">$25.00</label>
                </div>

  

            </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="50" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="50" type="radio"/>
                  <label for="50">$50.00</label>
                </div>

  

            </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="75" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="75" type="radio"/>
                  <label for="75">$75.00</label>
                </div>

  

            </b-col>

                 <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="100" :disabled="isDisabledCampos"  v-model="mount" @change="onChangeMount($event)" value="100" type="radio"/>
                  <label for="100">$100.00</label>
                </div>

  

            </b-col>

              <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

            <p
              class="textLabel"
              style="text-align: center;  margin-bottom: 5px;margin-top: 10px;"
            >
              Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

            <PincodeInput
              v-model="pin"
              placeholder="-"
              :length="6"
              :secure="true"
              required
              :autofocus="false"
              :disabled="isDisabledCampos"
            />

          </b-col>



             
          <b-col sm="12" md="12" lg="12" xl="12" style="margin-top:15px;justify-content: space-between;display: flex" >

<b-button 
                                        @click="atras"
                                        variant="outline-secondary"
                                        style="margin-right: 15px;"
                                      >
                                      <feather-icon

                                          icon="ArrowLeftCircleIcon"
                                          size="13"
                                          style= "margin-right:10px"
                                          />
                                        Atras 

                                      </b-button>
                 <b-button
                   id="btn_pay"
                    ref="btn_pay"
                    class="animacion_button"
                                variant="primary"
                                 type="submit"
                                :disabled="isDisabledPay"
                              >

                                Pagar ${{mount}}.00

                              </b-button>
              </b-col>


            </b-row>

            </b-form>
           

           

                  


          </b-row>

          
             
     

    

      </div>

    </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BForm
  } from 'bootstrap-vue'

  import PincodeInput from 'vue-pincode-input'
  
  export default {
    components: {
      BButton,
      BForm,
      BRow,
      BCol,
      BCard,
      PincodeInput,
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth', 'cards'],
    data() {
  
                          
  
      return {
        isDisabledSiguiente: true,
        showCards:true,
       cardId:"",

        isDisabledCampos:false,
       title:"",
       mount:0,
       isDisabledPay:true,


      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
  
  
    },
    mounted() {
  
 
        this.isDisabledSiguiente=true;
        this.showCards=true;
        this.cardId="";
        this.title="Seleccione una tarjeta de pago"
        this.$refs.modalTarjetas.show()
  
  
        
  
  
  
    },
    methods: {

 closeModalRecargasTarjetas(){
         
            this.$eventBus.$emit('reiniciarRecargasTarjetas');
    
      this.$eventBus.$emit('reiniciarRecargarTarjetaHome');




 },
        
    onChangeMount(event){

        this.mount = event.target.value;

            this.isDisabledPay=false;

        },
    onSubmit(event) {
      event.preventDefault()

      if(this.mount > 0){

        
      const userId_json = {
        userId: this.userId, pin: this.pin, mount: this.mount,  cardId: this.cardId
      }
      const user_string = JSON.stringify(userId_json)



      this.isDisabledPay = true
       this.isDisabledCampos = true

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

     

      document.getElementById('btn_pay').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando recarga'

      this.$https.post('/profile/recharge/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isDisabledPay = false
         this.isDisabledCampos = false

        document.getElementById('btn_pay').innerHTML = 'Pagar $' + this.mount + ".00"

        if (response.data.code == 200) {
      

              this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

           this.$refs.modalTarjetas.hide();
             this.$eventBus.$emit('reiniciarListadoRecargas');
             this.$eventBus.$emit('reiniciarRecargarTarjetaHome');
             this.$eventBus.$emit('reiniciarRecargarTarjetaHome2');
             this.$eventBus.$emit('reiniciarTransaccionesHome');

   

        } else {
   

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            


            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 502) {
            this.dni = ''
          } else if (response.data.code == 503) {
            this.pin = ''
          }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
      

      }else{

            this.$toast.error("Por favor seleccione un monto", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }




      

    
    },
    atras(){
      this.showCards=true;
      this.isDisabledSiguiente=true;
      this.title="Recargar billetera";
        this.title="Seleccione una tarjeta de pago"
    },
  
    hideCards(){
        this.showCards = false;
        this.mount="";
        this.pin="";
        this.isDisabledPay=true;
        this.title="Recargar billetera"
     },
        onChange(event) {
              this.cardId = event.target.value;
         
               this.isDisabledSiguiente=false;
          }, back(){

                this.showCards = true;
                this.showPay = false;
                this.title="Seleccione una tarjeta de pago"
                this.isDisabledSiguiente=true;

                },
  
     
    }
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  